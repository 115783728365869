.title___VTCiM {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.title___VTCiM h2 {
  margin-bottom: 0;
  font-size: 20px;
}
.title___VTCiM button {
  width: 100px;
}
.NewVirtualKds___23sQN {
  height: calc(100vh - 160px);
}
.NewVirtualKds___23sQN .ant-card .ant-card-body {
  padding: 24px 16px;
}
.NewVirtualKds___23sQN .setting-section .section-title {
  margin-bottom: 24px;
  font-size: 18px;
  line-height: 32px;
  color: #000000;
}
.NewVirtualKds___23sQN .setting-section .section-content .section-title {
  width: 200px;
  margin-right: 16px;
}
.NewVirtualKds___23sQN .setting-section .section-content .section-content-title {
  width: 200px;
  margin-right: 16px;
  font-size: 14px;
  font-weight: 600;
  line-height: 32px;
  color: #000000;
}
.NewVirtualKds___23sQN .ant-checkbox-wrapper {
  display: flex;
  align-items: center;
  height: 32px;
}
.NewVirtualKds___23sQN .ant-checkbox-wrapper .ant-checkbox {
  top: auto;
}
.NewVirtualKds___23sQN .ant-checkbox-wrapper > span:last-child {
  padding: 0 8px;
}
.NewVirtualKds___23sQN .ant-input-number-group-wrapper .ant-input-number-group-addon {
  min-width: 32px;
}
.NewVirtualKds___23sQN .ant-radio-wrapper {
  display: flex;
  align-items: center;
  height: 32px;
  margin-top: 4px;
}
.NewVirtualKds___23sQN .ant-radio-wrapper:first-child {
  margin-top: 0;
}
.NewVirtualKds___23sQN .ant-radio-wrapper .ant-radio {
  top: auto;
}
.NewVirtualKds___23sQN .ant-switch {
  transform: initial;
  transform-origin: initial;
}
